import { useLocation, useParams } from "react-router-dom";
import "./editFormPage.scss";
import EditForm from "./components/EditForm";
import Loading from "../../components/common/Loading";
import { useFormData } from "../../hooks/useFormData";
import { useContext, useEffect, useMemo } from "react";
import { useState } from "react";
import EditFormContext, { EditFormContextType } from "../../contexts/EditFormContext"
import { FormEntity, FormTemplateEntity, GroupEntity, ProjectEntity, ProjectTemplateRequirementEntity } from "../../domain/entities";
import { LoadingContext } from "../../contexts/LoadingContext";
function EditFormPage() {
  const location = useLocation();
  const { selectedFormId } = useParams<{ selectedFormId?: string }>() || {};
  const levelsFilter = location.state?.levelsFilter;

  const [form, setForm] = useState<FormEntity>(location.state?.form);
  const [formId, setFormId] = useState<FormEntity['id']>(parseInt(selectedFormId));
  const [template, setTemplate] = useState<FormTemplateEntity>();
  const [templateId, setTemplateId] = useState(null);

  const [name, setName] = useState<FormEntity['name']>(location.state?.name);
  const [paramValues, setParamValues] = useState<FormEntity['paramValues']>();
  const [fileNames, setFileNames] = useState<FormEntity['fileNames']>({});
  const [beneficialsMap, setBeneficialsMap] = useState<FormEntity['beneficialsMap']>({});
  const [groups, setGroups] = useState<GroupEntity[]>([]);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [selected, setSelected] = useState<EditFormContextType['selected']>({ pageId: null, paramName: null });
  const [project, setProject] = useState<ProjectEntity>();
  const [requirement, setRequirement] = useState<ProjectTemplateRequirementEntity>();
  const [requirementType, setRequirementType] = useState<number>();
  const [accessUser, setAccessUser] = useState<Record<string, boolean>>({});

  console.log(selectedFormId)

  const editFormContext: EditFormContextType = useMemo(
    () => ({
      form, setForm,
      formId, setFormId,
      template, setTemplate,
      name, setName,
      paramValues, setParamValues,
      fileNames, setFileNames,
      beneficialsMap, setBeneficialsMap,
      templateId, setTemplateId,
      groups, setGroups,
      completionPercentage, setCompletionPercentage,
      selected, setSelected,
      project, setProject,
      requirement, setRequirement,
      requirementType, setRequirementType,
      accessUser, setAccessUser
    }),
    [form, formId, template, name, paramValues, fileNames, beneficialsMap, templateId, groups, completionPercentage, selected, project, requirement, requirementType]
  );
  const { isLoading, setLoading } = useContext(LoadingContext);


  useFormData(editFormContext, levelsFilter);

  return (
    <EditFormContext.Provider value={editFormContext}>
      <div className="form-container form-page">
        <div className="d-flex flex-column">
          {isLoading ? (<Loading height="90vh" />) : (
            <EditForm />
          )}
        </div>
      </div>
    </EditFormContext.Provider>
  );
}

export default EditFormPage;
