import React, { useEffect, useState } from 'react';
import AuthRouter from './AuthRouter';
import AppRouter from './AppRouter';
import { getAuthUser } from '../../services/api/UserAuth';
import { BrowserRouter } from 'react-router-dom';
import { TranslationProvider } from '../../contexts/TranslationProvider';

function Router() {
    const { userToken, user } = getAuthUser();
    const [isLoggedIn, setIsLoggedIn] = useState(!!userToken && !!user);

    useEffect(() => {
        if (!userToken || !user) {
        } else {
            if (!isLoggedIn) setIsLoggedIn(true);
        }
    }, [userToken, user, isLoggedIn]);
    useEffect(() => {
        const handleLogout = () => setIsLoggedIn(false);
        const handleLogin = () => setIsLoggedIn(true);
        window.addEventListener("logout", handleLogout);
        window.addEventListener("login", handleLogin);
        return () => {
            window.removeEventListener("logout", handleLogout);
            window.removeEventListener("login", handleLogin);
        };
    }, []);
    return (
        <TranslationProvider>
            <BrowserRouter>
                {isLoggedIn ? <AppRouter /> : <AuthRouter isLoggedIn={isLoggedIn} />}
            </BrowserRouter>
        </TranslationProvider>
    );
}

export default Router;
