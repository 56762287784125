import React, { useContext } from 'react'
import AdvancedTableContext from '../../contexts/AdvancedTableContext'
import { useTranslation } from '../../contexts/TranslationProvider'
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import TableHeaderCell from './TableHeaderCell';
import DateColumnFilter from './DateColumnFilter';
import BasicColumnFilter from './BasicColumnFilter';

function TableHeader() {
  const { columns, data, sort, setSort, setAppliedFilters } = useContext(AdvancedTableContext)
  const { language } = useTranslation()
  return (
    <thead>
      <tr style={{ display: "flex" }}>
        {
          columns.map((column, index) => (
            <TableHeaderCell column={column} index={index} key={index} />
          ))
        }
      </tr>
      <tr className='table-filters' style={{ display: "flex" }}>
        {
          columns.map((column, index) => (
            <>
            {column.filtrable == false ? <th style={{ flex: column.width ?? 1 }} key={index} ></th> : <th style={{ flex: column.width ?? 1 }} key={index} className={`${language == "ar" && "text-right"}`}>
              {column.filter ? column.filter(setAppliedFilters) 
              : column.isDate ? <DateColumnFilter column={column} /> : <BasicColumnFilter column={column} />}
            </th>}
            </>
          ))
        }
      </tr>
    </thead>
  )
}

export default TableHeader