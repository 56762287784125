import React, { useContext, useEffect, useState } from "react";
import "../commonmodal.scss";
import apiClientWithLoading from "../../../services/api/ApiClient";
import Loading from "../../common/Loading";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { findLastIndex } from 'lodash';
import { ProjectClient } from "../../../services/api/ProjectClient";
import { ProjectEntity } from "../../../domain/entities";

const { default: EditSteoOnIcon } = require('../../../assets/edit_form_page_on.svg');
const { default: StepLine } = require('../../../assets/stepLine.svg');
const { default: Step } = require('../../../assets/active_assigned_G.svg');

const ProjectParamValueRow=({ commit, language }) =>{
  return (
    <tr key={commit.id}>
      <td style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: language !== "ar" ? "left" : "start" }}>
        {commit?.assignedBy?.email|| commit?.assignedById || ''}
      </td>
      <td style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: language !== "ar" ? "left" : "start" }}>
        {commit?.assignedToUser?.email|| ''}
      </td>
      <td style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: language !== "ar" ? "left" : "start" }}>
        {new Date(commit?.createdAt).toLocaleDateString(language)}
      </td>
      <td style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: language !== "ar" ? "left" : "start" }}>
        {new Date(commit?.updatedAt).toLocaleDateString(language)}
      </td>
    </tr>
  );
}
function AuditProjectModal({ onClose, project }) {
  const [loading, setLoading] = useState(false);
const apiClient = apiClientWithLoading();
const projectClient = new ProjectClient(apiClient);

  const [currentGroup, setCurrentGroup] = useState(0);
  const { t, language } = useTranslation();
  const [auditData, setAuditData] = useState<{ commits: ProjectEntity['commits'] } | null>(null);
useEffect(() => {
  const fetchAuditData = async () => {
    try {
      const response = await projectClient.getCommits(project.id);
      setAuditData(response);
    } catch (error) {
      console.error('Failed to fetch audit data:', error);
    }
  };

  fetchAuditData();
}, [project.id]);


  function groupCommits(commits) {
    if (!commits) return [];

    const sortedCommits = commits.sort((a, b) =>
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

    const finalGroupedCommits = [];
    let currentGroup = null;

    sortedCommits.forEach(commit => {
      const groupName = commit.assignedToGroup?.name || 'Commit';

      if (!currentGroup || currentGroup.name !== groupName) {
        if (currentGroup) {
          finalGroupedCommits.push(currentGroup);
        }
        currentGroup = { name: groupName, commits: [commit] };
      } else {
        currentGroup.commits.push(commit);
      }
    });

    if (currentGroup) {
      finalGroupedCommits.push(currentGroup);
    }

    return finalGroupedCommits;
  }

  const groupedCommits = groupCommits(auditData?.commits);

  let noSingleGroupVariable = groupedCommits.length === 1 && groupedCommits[0]?.name === "Commit";


  const commitGroupIndex = groupedCommits.findIndex(group => group.name === 'Commit');
  if (commitGroupIndex !== -1 && groupedCommits.length > 1) {
    const lastGroup = groupedCommits.splice(commitGroupIndex, 1)[0];
    groupedCommits[0].commits = groupedCommits[0].commits.concat(lastGroup.commits);
  }

  const groupedCommitsLength = groupedCommits.length - 1;
  useEffect(() => {
    setCurrentGroup(groupedCommitsLength <= 5 ? groupedCommitsLength : 0);
  }, [groupedCommitsLength]);

  if (groupedCommits.length > 6) {
    const lastElement = groupedCommits.pop();
    groupedCommits.unshift(lastElement);
  }

  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div id="project-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("pages.editionProject.popups.audit.title")}</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close" disabled={loading}
                  style={{ marginRight: language === "ar" ? "85%" : "" }}
                ></button>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div>
                  {!noSingleGroupVariable && groupedCommits.length > 0 && (
                    <div className={`audit-workflow-steps  ${language === "ar" ? "audit-workflow-steps-arabic" : ""}`}>
                      <div className="content-steps">
                        {groupedCommits.map((item, index) => (
                          <div key={index} className="edit-audit-step">
                            <div className="audit-step">
                              <img
                                src={currentGroup === index ? EditSteoOnIcon : Step}
                                height="24px"
                                width="auto"
                                alt=""
                                onClick={() => setCurrentGroup(index)}
                              />
                              <p className="step-name">{item.name}</p>
                            </div>
                            {index < groupedCommits.length - 1 && (
                              <img
                                src={StepLine}
                                className={`edit-audit-step-line ${language === "ar" ? "edit-audit-step-line-arabic" : ""}`}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="modal-body mt-3" style={{ maxHeight: "60vh", overflowY: "auto",paddingBottom:"50px" }}>
                    <table className="my-0 mt-3" style={{ borderCollapse: "collapse", width: "100%" }}>
                      <thead>
                        <tr>
                          <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: language !== "ar" ? "left" : "start" }}>
                            {t("pages.editionProject.popups.audit.assignedBy")}
                          </th>
                          <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: language !== "ar" ? "left" : "start", width: "250px" }}>
                            {t("pages.editionProject.popups.audit.assignedToUser")}
                          </th>
                          <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: language !== "ar" ? "left" : "start" }}>
                            {t("pages.editionProject.popups.audit.createdAt")}
                          </th>
                          <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: language !== "ar" ? "left" : "start" }}>
                            {t("pages.editionProject.popups.audit.updatedAt")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupedCommits[currentGroup]?.commits?.map((commit) => (
                          <ProjectParamValueRow key={commit.id} commit={commit} language={language} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuditProjectModal;
