import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../contexts/TranslationProvider";
import apiClientWithLoading from "../../services/api/ApiClient";
import { ContractClient } from "../../services/api/ContractClient";
import { FormClient } from "../../services/api/FormClient";
// import RessourceTableItem from "../Project/RessourceTableItem";
import ConfirmDeletePopup from "../../components/modals/ConfirmDetelePopup";
import RessourceTableItem from "../../components/common/newDesign/RessourceCard/RessourceTableItem";
import AdvancedTable from "../../components/AdvancedTable";
import { formatDate, getTypeLevels } from "../../helpers/helper";
import form from "../../assets/new-design-icons/Form.svg";
import contract from "../../assets/new-design-icons/Contract.svg";
import calculate from "../../assets/new-design-icons/Calculate.svg";
import { FiMoreHorizontal } from "react-icons/fi";
import { Ressource } from "../../components/common/RessourceCard";
import ContractCardActionsModal from "../../components/modals/ContractCardActionsModal";
import { toast } from "react-toastify";
import { set } from "lodash";

function GroupContractTable({ ressources, groups, reloadContracts }) {
  const formIcon = form.toString();
  const contractIcon = contract.toString();
  const docIcon = calculate.toString();
  const navigate = useNavigate();
  const { t, language } = useTranslation();
  const apiClient = apiClientWithLoading();
  const contractClient = new ContractClient(apiClient);
  const formClient = new FormClient(apiClient);
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const [ressourceToHandle, setRessourceToHandle] = useState(null);
  const [openActionPopup, setOpenActionPopup] = useState<{
    type: Ressource["type"];
    id: string | number;
  } | null>(null);
  const tableItems = useMemo(() => {
    const tableItems = [];
    groups.map((group) => {
      const groupRessources = ressources.filter(
        (ressource) => ressource.assignedToGroupId === group.id
      );
      groupRessources.map((ressource) => {
        tableItems.push({
          ...ressource,
          name: ressource.name,
          group: group.name,
          ressourceType: ressource.type,
          type: ressource.type === "Summary" ? [ressource.summary.level1, ressource.summary.level2, ressource.summary.level3]
              .filter((level) => level !== null)
              .map((level) => {
                const translatedName = level.translations.find(
                  (t) => t.language === language
                );
                if (translatedName) {
                  return translatedName.name;
                }
                return level.name;
              })
              .join("/") : getTypeLevels(ressource)
                .map((level) => {
                  const translatedName = level.translations.find(
                    (t) => t.language === language
                  );
                  if (translatedName) {
                    return translatedName.name;
                  } else {
                    return level.name;
                  }
                })
                .join("/"),
          status: ressource.status ? t("status." + ressource.status) : "",
          createdAt: ressource.createdAt,
          updatedAt: ressource.updatedAt,
          onClick: () => handleClick(ressource),
          onDelete: () => {
            setRessourceToHandle(ressource);
            setConfirmDelete(true);
          },
          actions: (
            <div className="actions">
              <button className="plus-icon" onClick={(e) => togglePopup(e, ressource)}>
                <FiMoreHorizontal size={20} />
              </button>
              {(openActionPopup?.type === ressource.type && openActionPopup?.id == ressource.id) && (
                <div className="">
                  <ContractCardActionsModal
                    onView={() => handleClick(ressource)}
                    onDelete={() => {
                      setRessourceToHandle(ressource);
                      setConfirmDelete(true);
                    }}
                  />
                </div>
              )}
            </div>
          )
        });
      });
    });
    return tableItems;
  }, [ressources, groups, openActionPopup, language]);
  const handleClick = (ressource) => {
    const path =
      ressource.type === "Contract"
        ? `/edition-contrat/${ressource.id}`
        : `/edit-form/${ressource.id}`;
    navigate(path);
  };

  const handleDelete = async (ressource) => {
    try {
      const deleteClient =
        ressource.type === "Contract" ? contractClient : formClient;
      const { deleted } = await deleteClient.delete(ressource.id);
      if (deleted) {
        await reloadContracts();
      }
      toast.success(t("pages.docxExport.popup.messages.successDelete"));
    } catch (err) {
      console.error("Error deleting resource:", err);
    }
  };
  const togglePopup = (e, ress) => {
    console.log(ress)
    e.stopPropagation();
    if (!ress)
      return setOpenActionPopup(null);
    if(openActionPopup?.type === ress.type && openActionPopup?.id === ress.id)
      return setOpenActionPopup(null);
    setOpenActionPopup({
      type: ress.type,
      id: ress.id,
    })
  };
  console.log(openActionPopup)
  return (
    <section id="#contracts-table" className="contracts-table">
              <AdvancedTable
          columns={[
            {
              display: t("pages.projects.tableHeaders.name"),
              accessorKey: "name",
              render: (row) => {
                const displayType = row.ressourceType;
                return (
                  <div className="title-table-item">
                    {displayType === "Form" ? (
                      <img
                        src={formIcon}
                        alt="icon"
                        style={{ width: "30px", height: "30px" }}
                      />
                    ) : displayType === "Contract" ||
                      displayType === "ContractAI" ||
                      displayType === "Summary" ? (
                      <img
                        src={contractIcon}
                        alt="icon"
                        style={{ width: "30px", height: "30px" }}
                      />
                    ) : displayType === "Document" ? (
                      <img
                        src={docIcon}
                        alt="icon"
                        style={{ width: "25px", height: "25px" }}
                      />
                    ) : (
                      <></>
                    )}
                    <div style={{ marginLeft: "20px" }}>{row.name}</div>
                  </div>
                )
              },
              width: 3
            },
            {
              display: t("pages.groupContracts.tableHeaders.group"),
              accessorKey: "group",
              width: 2
            },
            {
              display: t("pages.projects.tableHeaders.type"),
              accessorKey: "type",
              width: 2
            },
            {
              display: t("pages.projects.tableHeaders.status"),
              accessorKey: "status",
              width: 2,
              render: (row) => {
                return row.status ? (
                  <div className="status">{row.status}</div>
                ) : (
                  ""
                );
              }
            },
            {
              display: t("pages.projects.tableHeaders.createdAt"),
              accessorKey: "createdAt",
              render: (row) => {
                if (row.createdAt) {
                  return formatDate(row.createdAt);
                }
              },
              width: 2,
              isDate: true
            },
            {
              display: t("pages.projects.tableHeaders.updatedAt"),
              accessorKey: "updatedAt",
              render: (row) => {
                if (row.updatedAt) {
                  return formatDate(row.updatedAt);
                }
              },
              width: 2,
              isDate: true
            },
            {
              display: t("pages.projects.tableHeaders.actions"),
              accessorKey: "actions",
              sortable: false,
              filtrable: false,
              width: 2
            }
          ]}
          originalData={tableItems}
        />

      {showConfirmDelete && ressourceToHandle && (
        <ConfirmDeletePopup
          msgDelete={t("pages.contracts.msgDeleteRessource")}
          onClose={() => {
            setConfirmDelete(false);
            setRessourceToHandle(null);
            setOpenActionPopup(null);
          }}
          entityToDelete={ressourceToHandle.name || ""}
          onDelete={() => handleDelete(ressourceToHandle)}
        />
      )}
    </section>
  );
}

export default GroupContractTable;
