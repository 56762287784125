import React, { useContext, useEffect, useState } from "react";
import "../commonmodal.scss";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import Loading from "../../common/Loading";
import InputValuesContext from "../../../contexts/InputValuesContext";
import { mergeParams } from "../../../helpers/ParseTemplateData";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { findLastIndex } from 'lodash';
import { ContractClient } from "../../../services/api/ContractClient";
const { default: EditSteoOnIcon } = require('../../../assets/edit_form_page_on.svg');
const { default: StepLine } = require('../../../assets/stepLine.svg');
const { default: Step } = require('../../../assets/active_assigned_G.svg');


function ContractParamValueRow({ contractParamValue, fileNames, param, beneficialsMap, insertedBy, insertedAt }) {
  const { t, language } = useTranslation();

  let value = contractParamValue.value;
  let prevValue = contractParamValue.previousValue;
  if (!param) {
    console.error("Param is undefined for ContractParamValueRow");
    return null;
  }
  switch (param.type!) {
    case 'property':
      // value = beneficialsMap[value]?.name;
      // prevValue = beneficialsMap[prevValue]?.name;
      break;
    case 'beneficial':
      value = beneficialsMap[value]?.name;
      prevValue = beneficialsMap[prevValue]?.name;
      break;
    case 'beneficial[]':
      value = Array.isArray(value) && value.map(bId => beneficialsMap[bId]?.name ?? "").join(", ");
      prevValue = Array.isArray(prevValue) && prevValue.map(bId => beneficialsMap[bId]?.name ?? "").join(", ");
      break;
    case 'table':
      value = "";
      prevValue = "";
      break;
    case 'csv':
      const [csvTransposed, csvRows, csvName] = value ?? [false, [], ""];
      value = csvName as string ?? "";
      const [csvTransposedPrev, csvRowsPrev, csvNamePrev] = prevValue ?? [false, [], ""];
      prevValue = csvNamePrev as string ?? "";
      break;
    case 'file':
      value = fileNames[value];
      prevValue = fileNames[prevValue];
      break;
    case 'list':
      value = (value as any as number[])?.map(idx => idx + 1)?.join(",");
      prevValue = (prevValue as any as number[])?.map(idx => idx + 1)?.join(",");
      break;
    case 'enum':
      const arg = (param as any).args[value];
      value = arg?.option ?? "";
      const argPrev = (param as any).args[prevValue];
      prevValue = argPrev?.option ?? "";
      break;
    case 'boolean':
      value = ((value as any) == true && "Oui") || ((value as any) == false && "Non") || "";
      prevValue = ((prevValue as any) == true && "Oui") || ((prevValue as any) == false && "Non") || "";
      break;
    case 'comment':
    case 'date':
    case 'number':
    case 'string':
      break;
    default:
      break;
  }

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  return (
    <tr>
      <td style={{ border: "1px solid black", padding: "2px", textAlign: language != "ar" ? "left" : "start" }}>
        {contractParamValue.name}
      </td>
      <td style={{ border: "1px solid black", padding: "2px", textAlign: language != "ar" ? "left" : "start" }}>
        {prevValue}
      </td>
      <td style={{ border: "1px solid black", padding: "2px", textAlign: language != "ar" ? "left" : "start" }}>
        {value}
      </td>
      <td style={{ border: "1px solid black", padding: "2px", textAlign: language != "ar" ? "left" : "start" }}>
        {formattedDate.format(new Date(insertedAt))}
      </td>
      <td style={{ border: "1px solid black", padding: "2px", textAlign: language != "ar" ? "left" : "start" }}>
        {insertedBy}
      </td>
    </tr>
  );
}

function ContractAuditModal({ onClose }) {
  const [loading, setLoading] = useState(false);
  const {
    templateData,
    contractId,
    contract,
    groupContract,
  } = useContext(InputValuesContext);
  const params = mergeParams(templateData)
  const { t, language } = useTranslation();
  let [auditData, setAuditData] = useState({
    contractParamValues: [],
    commits: [],
    fileNames: {},
    beneficialsMap: {},
  });
  const apiClient = useApiClientWithLoading(setLoading);
  useEffect(() => {
    const contractClient = new ContractClient(apiClient)
    contractClient.getContractParamValues(contractId).then(({ beneficialsMap, contractParamValues, commits, fileNames }) => {
      setAuditData({ beneficialsMap, contractParamValues, commits, fileNames });
    });
  }, [contractId, contract?.assignedToGroupId]);
  function groupCommits(commits) {
    const sortedCommits = commits?.sort((a, b) =>
      new Date(a?.commitedAt as string).getTime() - new Date(b?.commitedAt as string).getTime()
    );
    const finalGroupedCommits = [];
    let currentGroup = null;
    sortedCommits.forEach(commit => {
      const groupName = commit?.group?.name || 'Commit';
      if (!currentGroup || currentGroup.name !== groupName) {
        if (currentGroup) {
          finalGroupedCommits.push(currentGroup);
        }
        currentGroup = { name: groupName, commits: [commit] };
      } else {
        currentGroup.commits.push(commit);
      }
    });

    if (currentGroup) {
      finalGroupedCommits.push(currentGroup);
    }

    return finalGroupedCommits;
  }

  const groupedCommits = groupCommits(auditData?.commits);
  let lastIndex = findLastIndex(groupedCommits, { name: groupContract });

  let noSingleGroupVariable;
  if (groupedCommits[0]?.name === "Commit" && groupedCommits?.length === 1) {
    noSingleGroupVariable = true;
  }
  if (!(lastIndex == groupedCommits.length - 1)) {
    let newGroup = { name: groupContract, commits: [] };
    groupedCommits.push(newGroup)
  }
  const [currentGroup, setCurrentGroup] = useState(0);
  const commitGroupIndex = groupedCommits?.findIndex(group => group.name === 'Commit');

  if (commitGroupIndex !== -1 && groupedCommits.length > 1) {
    const lastGroup = groupedCommits?.splice(commitGroupIndex, 1)[0];
    const nextGroup = groupedCommits[0];
    nextGroup.commits = nextGroup.commits?.concat(lastGroup?.commits);
  }
  let groupedCommitsLength = groupedCommits?.length - 1
  useEffect(() => {
    groupedCommitsLength <= 5 ? setCurrentGroup(groupedCommitsLength) : setCurrentGroup(0)
  }, [groupedCommitsLength]);

  if (groupedCommits.length > 6) {
    const lastElement = groupedCommits.pop();
    groupedCommits.unshift(lastElement)
  }

  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("pages.editionContract.popups.audit.title")}</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close" disabled={loading}
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="">
                  {!noSingleGroupVariable && groupedCommits.length > 0 &&
                    <div className={`audit-workflow-steps  ${language == "ar" && "audit-workflow-steps-arabic"} `}>
                      <div className="content-steps">
                        {groupedCommits.map((item, index) => (
                          <div key={index} className="edit-audit-step">
                            <div className="audit-step">
                              <img
                                src={
                                  currentGroup === index
                                    ? EditSteoOnIcon
                                    : Step
                                }
                                height="24px"
                                width="auto"
                                alt=""
                                onClick={() => setCurrentGroup(index)}
                              />
                              <p className="step-name">{item.name}</p>
                            </div>
                            {index < groupedCommits.length - 1 && (
                              <img
                                src={StepLine}
                                className={`edit-audit-step-line  ${language == "ar" && "edit-audit-step-line-arabic"}`}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>}

                  {loading ? (
                    <Loading height="50vh" />
                  ) : (
                    <div className="modal-body mt-3" style={{ maxHeight: "60vh", overflowY: "auto" }}>
                      <table className="my-0 mt-3" style={{ borderCollapse: "collapse", width: "100%" }}>
                        <thead>
                          <tr>
                            <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: language != "ar" ? "left" : "start" }} >
                              {t("pages.editionContract.popups.audit.name")}
                            </th>
                            <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: language != "ar" ? "left" : "start", width: "190px" }} >
                              {t("pages.editionContract.popups.audit.previousValue")}
                            </th>
                            <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: language != "ar" ? "left" : "start" }} >
                              {t("pages.editionContract.popups.audit.value")}
                            </th>
                            <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: language != "ar" ? "left" : "start", width: "125px" }} >
                              {t("pages.editionContract.popups.audit.insert_at")}
                            </th>
                            <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: language != "ar" ? "left" : "start" }} >
                              {t("pages.editionContract.popups.audit.insert_by")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {groupedCommits[currentGroup == null ? groupedCommits.length - 1 : currentGroup]?.commits?.map((commit, commitIndex) =>
                            commit?.changes?.map((change, changeIndex) => {
                              return (
                                <ContractParamValueRow
                                  key={`${commitIndex}-${changeIndex}`}
                                  contractParamValue={change}
                                  fileNames={auditData.fileNames}
                                  beneficialsMap={auditData.beneficialsMap}
                                  param={params.find((p) => p.name === change.name)}
                                  insertedBy={commit.commitedBy?.email}
                                  insertedAt={commit.commitedAt}
                                />
                              );
                            })
                          )}
                        </tbody>

                      </table>
                      <div className="modal-footer">
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractAuditModal;
