import React, { useMemo, useState, useContext, useEffect } from 'react';
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { FormParam, FormType } from '../../../../domain/types/FormParams';
import { validateFormula } from '../../../../domain/Form';
import EditFormTemplateContext from '../../../../contexts/EditFormTemplateContext';
import { getAllParams } from '../../../../domain/FormTemplate';
import ConstraintRender from './ConstraintRender';

function NumberRender({
  param,
  value,
  valueName,
  isEditing,
  onChange,
  onChangeName,
  onChangeFormula,
  onChangeConstraint,
  errors,
  onDescriptionChange
}: {
  param: FormType.NumberParam;
  value: FormParam['label'];
  valueName: FormParam['name'];
  isEditing: boolean;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChangeName: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChangeFormula: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChangeConstraint: (constraints: FormType.Constraint[]) => any;
  errors: { where: string; why: string; }[];
  onDescriptionChange: React.ChangeEventHandler<HTMLTextAreaElement>;
}) {
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const { formTemplate } = useContext(EditFormTemplateContext);
  const params = getAllParams(formTemplate);
  const [filteredFormulaParams, setFilteredFormulaParams] = useState([]);
  const [filteredFormulas, setFilteredFormulas] = useState([]);
  const [filteredConstraints, setFilteredConstraints] = useState([]);
  useEffect(() => {
    const filteredFormulaParams = otherParams.filter(p => p.name.toLowerCase().includes(formulaInput?.toLowerCase()));
    const filteredFormulas = predefinedFormulas.filter(f => f.toLowerCase().includes(formulaInput?.toLowerCase()));
    setFilteredFormulaParams(filteredFormulaParams);
    setFilteredFormulas(filteredFormulas);
    setFilteredConstraints(filteredConstraints);
  }, []);
  const [formulaInput, setFormulaInput] = useState(param.formula ?? "");
  const [showFormulaDropDown, setShowFormulaDropDown] = useState(false);

  const otherParams = useMemo(() => getAllParams(formTemplate)
    .filter(p => ['number', 'boolean', 'enum', 'date'].includes(p.type))
    .filter(p => p.name !== param.name), [formTemplate]);

  const unvalidFormula = useMemo(() => param.formula && !validateFormula(param.formula, otherParams), [param?.formula, otherParams]);
  const predefinedFormulas = ['LCM', 'now', 'dateDiffInYears', 'calAge'];
  const formulaOperators = ['+', '-', '*', '/', ',', '(', ')', ':', '?'];
  const handleFormulaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setFormulaInput(value);
    onChangeFormula(e);

    const lastPart = value.split(new RegExp(`[${formulaOperators.map(op => '\\' + op).join('')}]`)).pop()?.trim();
    if (lastPart) {
      setFilteredFormulaParams(otherParams.filter(p => p.name.toLowerCase().includes(lastPart.toLowerCase())));
      setFilteredFormulas(predefinedFormulas.filter(f => f.toLowerCase().includes(lastPart.toLowerCase())));
    }

    setShowFormulaDropDown(lastPart && lastPart.length > 0);
  };

  const replaceLastPart = (selectedValue: string, operators: string[], input: string, onClose: () => void) => {
    const regex = new RegExp(`([^${operators.join('\\')}]+)$`);
    const newFormula = input.replace(regex, selectedValue);
    setFormulaInput(newFormula);
    onClose();
  };
  return (
    <div className={`input-param`}>
      <div className="input-type">{param.type}</div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">
            {translationPath.parameterName} : {param.name}
          </label>
        ) : (
          <>
            <textarea
              className="custom-form-textarea"
              value={valueName}
              onChange={onChangeName}
              placeholder={translationPath.parameterName}
            />
            {errors.map((err, idx) => {
              if (err.where === "name") {
                return <div key={idx} className="error-message-dialog">{err.why}</div>;
              }
            })}
          </>
        )}
      </div>
      <div>
        {isEditing ? (
          <textarea
            className="custom-form-textarea"
            value={value}
            onChange={onChange}
            placeholder={translationPath.parameterLabel}
          />
        ) : (
          <div style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">
            {translationPath.parameterLabel} : {param.label}
          </div>
        )}
      </div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterDescription} :{param.description ?? ""}</label>
        ) : (
          <textarea
            className="custom-form-textarea"
            value={param.description ?? ""}
            onChange={(e) => onDescriptionChange(e)}
            placeholder={translationPath.parameterDescription}
          />
        )}
      </div>
      <div>
        {isEditing ? (
          <div style={{ position: "relative" }}>
            <textarea
              className="custom-form-textarea"
              value={formulaInput ? formulaInput : param.formula ?? ""}
              onChange={handleFormulaChange}
            />
            {showFormulaDropDown && (filteredFormulaParams.length > 0 || filteredFormulas.length > 0) && (
              <ul className="dropdown-list-formula">
                {filteredFormulaParams.map((p, idx) => (
                  <li key={idx} onClick={() => { replaceLastPart(p.name, formulaOperators, formulaInput, () => setShowFormulaDropDown(false)); setShowFormulaDropDown(false); }}>
                    {p.name}
                  </li>
                ))}
                {filteredFormulas.map((f, idx) => (
                  <li key={idx} onClick={() => { replaceLastPart(f, formulaOperators, formulaInput, () => setShowFormulaDropDown(false)); setShowFormulaDropDown(false); }}>
                    {f}
                  </li>
                ))}
              </ul>
            )}
            {unvalidFormula && <div className="error-message-dialog">{translationPath.unvalidFormula}</div>}
          </div>
        ) : (
          <div style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">
            {translationPath.parameterFormula} : {param.formula ?? ""}
          </div>
        )}
      </div>
      <div>
        <ConstraintRender constraints={param.constraints} isEditing={isEditing} param={param} onChangeConstraint={onChangeConstraint} params={params} />
      </div>
    </div>
  );
}

export default NumberRender;
