import "./contractTemplatesPage.scss";
import { BsSearch } from "react-icons/bs";
import { useEffect, useState, useContext, useCallback } from "react";
import useApiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import RessourceCard from "../../components/common/RessourceCard";
import { useNavigate } from "react-router-dom";
import form from "../../assets/new-design-icons/Form.svg"
import contract from "../../assets/new-design-icons/Contract.svg"
import {
  ContractTemplateEntity,
  TypeLevel1Entity,
} from "../../domain/entities";
import { ContractTemplateClient } from "../../services/api/ContractTemplateClient";
import { useTranslation } from "../../contexts/TranslationProvider";
import { getName } from "../../helpers/Translation";
import { LoadingTemplateContext } from "../../contexts/LoadingTemplateContext";
import ConfimrDeleteMessage from "../../components/modals/ConfirmDeteleMessage";
import AdminRessourceCard from "../../components/common/newDesign/AdminRessourceCard";
import LayoutFilter from "../../components/LayoutFilter";
import TemplateTableItem from "../../components/common/newDesign/AdminRessourceCard/TemplateTableItem";
import AdvancedTable from "../../components/AdvancedTable";
import { formatDate, getTypeLevels } from "../../helpers/helper";
import ContractCardActionsModal from "../../components/modals/ContractCardActionsModal";
import { FiMoreHorizontal } from "react-icons/fi";

function ContractTemplatesPage() {
  const formIcon = form.toString();
  const contractIcon = contract.toString();
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState<ContractTemplateEntity[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { t, language } = useTranslation();
  const [displayCards, setDisplayCards] = useState("grid");
  const apiClient = useApiClientWithLoading();
  const contractTemplateClient = new ContractTemplateClient(apiClient);
  const { loadingTemplates } = useContext(LoadingTemplateContext);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [templateOpenPopupId, setTemplateOpenPopupId] = useState<string | number | null>(null);
  const [templateToDelete, setTemplateToDelete] =
    useState<ContractTemplateEntity | null>(null);
  const loadTemplates = useCallback(async () => {
    try {
      const allTemplates = await contractTemplateClient.getAll();
      setTemplates(allTemplates.rows);
      setLoading(false); // Set loading to false after contracts are fetched
    } catch (error) {
      console.error("Error fetching contracts: ", error);
      setLoading(false); // Also set loading to false in case of error
    }
  }, []);
  useEffect(() => {
    loadTemplates();
  }, [loadTemplates]);
  const filteredTemplates = templates?.filter((d) =>
    d!.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const navigate = useNavigate();
  const handleClick = (templateId) => {
    navigate(`/edition-contrat-template/${templateId}`);
  };
  const sortedContracts = filteredTemplates.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );
  const templatesPerLevel1: Record<
    TypeLevel1Entity["name"],
    ContractTemplateEntity[]
  > = {};

  const levels1Names = [
    ...new Set(
      filteredTemplates.map((template) => getName(template.level1, language))
    ),
  ];
  levels1Names.forEach((level1Name) => (templatesPerLevel1[level1Name] = []));

  sortedContracts.forEach((template) => {
    templatesPerLevel1[getName(template.level1, language)].push(template);
  });
  const handleDelete = async (id) => {
    const template = templates?.find((template) => template.id === id);
    if (template) {
      setTemplateToDelete(template);
      setConfirmDeleteVisible(true);
    }
  };
  const handleDesactivate = async (id) => {
    try {
      await contractTemplateClient.deactivate(id);
      loadTemplates();
    } catch (error) {
      console.error("Error desactivating contract: ", error);
    }
  };
  const handleLayoutChange = (layout) => {
    setDisplayCards(layout);
  };
  const togglePopup = (e, id) => {
    e.stopPropagation();
    if (templateOpenPopupId === id) {
      setTemplateOpenPopupId(null);
    }
    else {
      setTemplateOpenPopupId(id);
    }
  }
  return (
    <div className="templates px-5">
      <section id="templates" className="pt-2">
        <span className="current-pathname">
          {t("pages.templates.contractTitle")}
        </span>
        <div className="search-zone">
          <div className="d-flex mt-4 ps-1 align-items-center justify-content-between">
            <div className="search-bar w-75">
              <BsSearch
                className="search-icon"
                style={{ left: language == "ar" && "84px" }}
              />
              <input
                type="text"
                placeholder={t("pages.templates.search.placeholder")}
                className="search-input"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
            <div className="filter d-flex gap-4 align-items-center"></div>
          </div>
        </div>
        <LayoutFilter onLayoutChange={handleLayoutChange} />
        <div className="mt-5">
  {loading ? (
    <Loading height="75vh" />
  ) : displayCards === "grid" ? (
    <div className="templates-list-container">
      {levels1Names?.map((level1Name) => (
        <div className="templates-space" key={level1Name}>
          <h3 className="m-3 templates-space-title">{level1Name}</h3>
          <div className="templates-per-level">
            {templatesPerLevel1[level1Name]
              .slice()
              .sort((a, b) => (a.updatedAt as any) - (b.updatedAt as any))
              .map((item) => {
                const isLoading = loadingTemplates.some(
                  (template) =>
                    template.id === item.id && template.type === "ContractTemplate"
                );
                return (
                  <div key={item.id} className="m-2" id={`template ${item.id}`}>
                    <AdminRessourceCard
                      data={{ type: "ContractTemplate", ...item }}
                      onClick={() => handleClick(item.id)}
                      onDelete={() => handleDelete(item.id)}
                      onDesactivate={
                        item.activated ? () => handleDesactivate(item.id) : undefined
                      }
                      loading={isLoading}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      ))}
    </div>
  ) : (
   <>
           <AdvancedTable
          columns={[
            {
              display: t("pages.projects.tableHeaders.name"),
              accessorKey: "name",
              render: (row) => {
                const displayType = row.ressourceType;
                return (
                  <div className="title-table-item">
                      <img
                        src={contractIcon}
                        alt="icon"
                        style={{ width: "30px", height: "30px" }}
                      />
                    <div style={{ marginLeft: "20px" }}>{row.name}</div>
                  </div>
                )
              },
              width: 3
            },
            {
              display: t("pages.projects.tableHeaders.type"),
              accessorKey: "type",
              width: 2
            },
            {
              display: t("pages.projects.tableHeaders.status"),
              accessorKey: "status",
              width: 2,
              render: (row) => {
                return row.activated != undefined ? (
                  <>{
                      <div className={`${row.activated ? "status-active" : "status"}`}>
                        {(row.activated ? t("pages.ressources.cardLabels.activeTrue") : t("pages.ressources.cardLabels.activeFalse"))}
                      </div>
                  }</>
                ) : (
                  ""
                );
              }
            },
            {
              display: t("pages.projects.tableHeaders.createdAt"),
              accessorKey: "createdAt",
              render: (row) => {
                if (row.createdAt) {
                  return formatDate(row.createdAt);
                }
              },
              width: 2,
              isDate: true
            },
            {
              display: t("pages.projects.tableHeaders.updatedAt"),
              accessorKey: "updatedAt",
              render: (row) => {
                if (row.updatedAt) {
                  return formatDate(row.updatedAt);
                }
              },
              width: 2,
              isDate: true
            },
            {
              display: t("pages.projects.tableHeaders.actions"),
              accessorKey: "actions",
              sortable: false,
              filtrable: false,
              width: 2
            }
          ]}
          originalData={filteredTemplates.map((ressource) => ({
            ...ressource,
            status: ressource.activated ? t("pages.ressources.cardLabels.activeTrue") : t("pages.ressources.cardLabels.activeFalse"),
            type: getTypeLevels(ressource)
                .map((level) => {
                  const translatedName = level.translations.find(
                    (t) => t.language === language
                  );
                  if (translatedName) {
                    return translatedName.name;
                  } else {
                    return level.name;
                  }
                })
                .join("/"),
            actions: (
              <div className="actions">
                <button className="plus-icon" onClick={(e) => togglePopup(e, ressource.id)}>
                  <FiMoreHorizontal size={20} />
                </button>
                {(templateOpenPopupId == ressource.id) && (
                  <div className="">
                    <ContractCardActionsModal
                      onView={() => handleClick(ressource.id)}
                      onDelete={() => handleDelete(ressource.id)}
                      onDesactivate={ressource.activated ? () => {
                        setTemplateOpenPopupId(null)
                        handleDesactivate(ressource.id)
                      } : undefined}
                    />
                  </div>
                )}
              </div>
            ),
        onClick: () => handleClick(ressource.id),
          }))}
        />
   </>
  )}
</div>

      </section>
      {confirmDeleteVisible && templateToDelete && (
        <ConfimrDeleteMessage
          reloadTemplates={loadTemplates}
          onClose={() => {
            setTemplateToDelete(null)
            setTemplateOpenPopupId(null)
            setConfirmDeleteVisible(false)
          }}
          template={templateToDelete}
        />
      )}
    </div>
  );
}

export default ContractTemplatesPage;
