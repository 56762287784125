import React, { useContext, useState } from 'react'
import { useTranslation } from '../../contexts/TranslationProvider'
import AdvancedTableContext from '../../contexts/AdvancedTableContext'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoMdSearch } from "react-icons/io";

function BasicColumnFilter({column}) {
  const { columns, data, sort, setSort, setAppliedFilters, appliedFilters } = useContext(AdvancedTableContext)
  const { t, language } = useTranslation()
  const [searchTerm, setSearchTerm] = useState(appliedFilters.find(filter => filter.key === column.accessorKey)?.value ?? "")

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
    setAppliedFilters((prevFilters) => {
      const newFilters = prevFilters.filter(filter => filter.key !== column.accessorKey)
      if (e.target.value) {
        newFilters.push({ key: column.accessorKey, value: e.target.value, type: "basic" })
      }
      return newFilters
    })
  }

  const handleResetClick = () => {
    setSearchTerm("")
    setAppliedFilters((prevFilters) => {
      return prevFilters.filter(filter => filter.key !== column.accessorKey)
    })
  }

  return (
    <div className="basic-filter-container" style={{ position: "relative" }}>
      <input
        type="text"
        placeholder={t("pages.projects.filterPlaceholder")}
        value={searchTerm}
        onChange={handleSearchChange}
        style={{ zIndex: 0, position: "sticky" }}
      />
      {searchTerm ? (
        <IoIosCloseCircleOutline
        className="input-icon"
        onClick={handleResetClick}
        style={{
          top: "20px",
          marginRight: language === "ar" ? "150px" : "auto",
          color: "gray",
          cursor: "pointer",
          position: "absolute",
          right: "10px", // Adjust as needed for positioning
          zIndex: 99,
          pointerEvents: "auto"
        }}/>
      ) : (
        <IoMdSearch
          className="input-icon"
          style={{
            marginRight: language === "ar" ? "150px" : "auto",
            color: "gray",
            cursor: "pointer",
            position: "absolute",
            right: "10px", // Adjust as needed for positioning
            zIndex: 1
          }}
        />
      )}
    </div>
  )
}

export default BasicColumnFilter
