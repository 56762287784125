import { create, all, MathNode, evaluate } from 'mathjs';
import writtenNumber from 'written-number';

export const math = create(all);
export const dateDiffY = (a: Date, b: Date): number => {
  const start = new Date(b);
  const end = new Date(a);

  let yearsDiff = end.getFullYear() - start.getFullYear();
  const monthDifference = end.getMonth() - start.getMonth();

  // Adjust if the anniversary hasn't been reached in the current year
  if (monthDifference < 0 || (monthDifference === 0 && end.getDate() < start.getDate())) {
    yearsDiff--;
  }

  return yearsDiff;
}
// Return the string of a number
export const numberToLetters = (num: number): string => {
  const letters = writtenNumber(num, { lang: localStorage.getItem('language') || 'fr' });
  return letters;
}

export const numberToLettersCAPS = (num: number): string => {
  return numberToLetters(num).toUpperCase();
}

// Add a custom LCM function
math.import({
  LCM: (a: number, b: number): number => {
    // Function to calculate the Least Common Multiple (LCM)
    const gcd = (x: number, y: number): number => y === 0 ? x : gcd(y, x % y);
    return Math.abs(a * b) / gcd(a, b);
  },
  // Function to calculate the length of a number
  len: (a: number | string): number => {
    return a.toString().length;
  }
});
// Add custom functions for date handling
math.import({
  // Return current date in YYYY-MM-DD format
  now: (): string => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Returns 'YYYY-MM-DD'
  },

  // Calculate the difference in years between two dates
  dateDiffInYears: (startDate: string, endDate: string): number => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    let yearsDiff = end.getFullYear() - start.getFullYear();
    const monthDifference = end.getMonth() - start.getMonth();

    // Adjust if the anniversary hasn't been reached in the current year
    if (monthDifference < 0 || (monthDifference === 0 && end.getDate() < start.getDate())) {
      yearsDiff--;
    }

    return yearsDiff;
  },
  calAge: (dateOfBirth: string): number => {
    const dob = new Date(dateOfBirth);
    const today = new Date();
    const age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();

    // Adjust if the birthday hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
      return age - 1;
    }

    return age;
  },
  lessThanDate: (date1: string, date2: string): boolean => {
    return new Date(date1) < new Date(date2);
  },
  greaterThanDate: (date1: string, date2: string): boolean => {
    return new Date(date1) > new Date(date2);
  },
  equalDate: (date1: string, date2: string): boolean => {
    return new Date(date1).getTime() === new Date(date2).getTime();
  }
});
// add functions for string manipulation
math.import({
  // Return the string of a number
  numberToLetters,
  numberToLettersCAPS,
});
